













import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ReportView from '@/components/helper/report-view.vue';

class PageReportRequest extends PageRequest {
    lawyer: number = null;
    state: number = null;
    date_from: string = new Date().toISOString().substr(0, 4) + '-01-01';
    date_to: string = new Date().toISOString().substr(0, 10);
    form: number = 0;
}

@Component({
    components: { ReportView },
})
export default class QueryLawyers extends ListBase {
    pagerequest: PageReportRequest = new PageReportRequest();
    entity: string = 'lawyer';

    columns = [
        { value: 'lawyer_code', text: this.L('Matricula'), type: 'text', sortable: false },
        { value: 'laywer_document', text: 'DNI', type: 'text', sortable: false },
        { value: 'laywer_name', text: 'Nombre Agremiado', type: 'text', sortable: false },
        { value: 'amount', text: 'Monto', type: 'price', sortable: false },
        { value: 'application_date', text: 'Fecha de Solicitud', type: 'text', sortable: false },
        { value: 'account', text: 'Nro de Cuenta', type: 'text', sortable: false },
        { value: 'cci', text: 'CCI', type: 'text', sortable: false },
        { value: 'state_name', text: 'Estado', type: 'chip', color: 'state_color', sortable: false },
        { value: 'comments', text: 'Comentarios', type: 'text', sortable: false },
    ];
    
    filterItems = [ 
        this.gItem({ type: 'object', prop: 'lawyer', label: 'Buscar Agremiado por Nombre, Matricula o Documento', itemText: 'description_full', autocomplete: true, searchLen: 2, size: 3, returnObject: false, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'object', prop: 'state', label: 'Estado', size: 2, generalType: 'E_APPLICATION', hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Solicitudes Desde', size: 2, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'radio', prop: 'form', label: '', size: 3, options: 'form_opts', hideDetails: true, row: true, onchange: this.getpage }),
    ];

    /************* BOTONES DE ACCION ***************/

    async getpage() {
        let list: any = this.$refs.list
        await list.getpage();
    }
    
    created() {

    }
    
}
