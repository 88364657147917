


















































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import AbpBase from '../../lib/abpbase';
import Util from '../../lib/util'
import VWidget from '../VWidget.vue';
import FormBase from '@/components/helper/form-base.vue';
import PageRequest from '../../store/entities/page-request';
import XLSX from 'xlsx'

@Component({
    components: { VWidget, FormBase },
})

export default class ListView extends AbpBase {
    @Prop({ type: String }) entity: string;
    @Prop({ type: String }) action: string;
    @Prop({ type: Array }) columns: Array<any>;
    @Prop({ type: Array }) exportColumns: Array<any>;
    @Prop({ type: Array }) filterItems: Array<any>;
    @Prop({ type: String, default: '#f8f8f9' }) toolbarColor: string;
    @Prop({ type: String, default: 'Consulta de Registros' }) description: string;
    @Prop({ type: String, default: 'Reporte' }) title: string;
    @Prop({ type: String, default: 'assessment' }) icon: string;
    @Prop({ type: String, default: 'loading' }) loadingAttr: string;
    @Prop({ type: String, default: 'reportList' }) listAttr: string;
    @Prop({ type: String, default: '' }) exportname: string;
    @Prop({ type: String, default: null }) groupBy: string;
    @Prop({ type: Boolean, default: true }) showToolbar: boolean;
    @Prop({ type: Boolean, default: true }) showExport: boolean;
    @Prop({ type: Boolean, default: true }) showQuery: boolean;
    @Prop({ type: Boolean, default: true }) showPagination: boolean;
    @Prop({ type: Boolean, default: false }) showHeader: boolean;
    @Prop({ type: Boolean, default: false }) showFooter: boolean;
    @Prop({ type: Boolean, default: false }) showGroupHeader: boolean;
    @Prop({ type: Boolean, default: false }) showSelect: boolean;
    @Prop({ type: Boolean, default: false }) showExpand: boolean;
    @Prop({ type: Boolean, default: false }) hideHeaders: boolean;
    @Prop({ type: Boolean, default: false }) exportServerFile: boolean;
    @Prop({ type: Boolean, default: true }) autoLoad: boolean;
    @Prop({ type: PageRequest, default: () => { return new PageRequest() } }) pagerequest: PageRequest;
    propValue(data: any, field: string) {
        let value = Util.getEntityValue(data, field);
        return value;
    }
    getColor(data: any, col: any) {
        let style = 'none';
        if (col.color != undefined) {
            if (col.color == 'extra' && col.generalType == true) {
                let val = this.propValue(data, col.value);
                style = Util.abp.list.getItem(val).extra;
            } else
                style = this.propValue(data, col.color);
        }
        return style;
    }
    propData(data: any, col: any) {
        let value = this.propValue(data, col.value);
        if (col.generalType) value = Util.abp.list.getName(value);

        if (col.type == 'percent')
            value += ' %';
        else if (col.type == 'price') {
            let sign = (data['currency_sign'] != undefined ? data['currency_sign'] : Util.abp.setting.get('DefaultMonedaSign'));
            value = sign + ' ' + (value != null ? parseFloat(value).toFixed(2) : '');
        } else if (col.type == 'number') {
            value = (value != null ? parseFloat(value).toFixed(2) : '');
        } else if (col.type == 'date') {
            value = (value != null ? value.substr(0, 10) : '');
        } else if (col.type == 'datetime') {
            value = (value != null ? value.replace('T', ' ') : '');
        }
        if (value == null) value = '';
        return value;
    }
    get list() {
        return this.$store.state[this.entity][this.listAttr];
    };
    get loading() {
        return this.$store.state[this.entity][this.loadingAttr];
    };
    get pageSize() {
        return this.$store.state[this.entity].pageSize;
    };
    get totalCount() {
        return this.$store.state[this.entity].reportList != null ? this.$store.state[this.entity].reportList.length : 0;
    };
    get filename() {
        return this.$store.state[this.entity].filename;
    };

    @Emit('on-getpage')
    async getpage() {
        let valid = true;
        if (this.filterItems != null && this.$refs.filters != undefined)
            valid = (this.$refs.filters as any).validate()
        if (this.action != null && valid) {
            await this.$store.dispatch({
                type: this.entity + '/' + this.action,
                data: this.pagerequest
            });
        }
    }

    @Emit('on-select-items')
    onSelecteItems(items) {

    }

    exportToExcel() {
        if (this.exportServerFile) {
            if (this.filename != '' && this.filename != undefined)
                window.location = this.filename;
        } else {
            var columns = this.columns;
            if (this.exportColumns != null)
                columns = this.exportColumns;

            let dataList = Util.getListToExport(this.list, columns);
            let mai = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "U", "V", "W", "X", "Y", "Z"];

            let data = XLSX.utils.json_to_sheet(dataList.list, { header: dataList.header });

            dataList.headerTitle.forEach((title, index) => {
                if (data[mai[index] + '1'] != undefined)
                    data[mai[index] + '1'].v = title;
            });


            let filename = this.entity + '-list';
            if (this.exportname != '')
                filename = this.exportname;

            const workbook = XLSX.utils.book_new();
            //const filename = this.entity + '-list';
            XLSX.utils.book_append_sheet(workbook, data, filename);
            XLSX.writeFile(workbook, `${filename}.xlsx`);
        }
    }

    async mounted() {
        if (this.autoLoad)
            this.getpage();
    }
}
